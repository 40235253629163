import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const BusinessUserLogin_old = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();  // Use

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/api/BusinessUser/loginbusinessuser`, {
        email,
        password,
        rememberMe,
      });

      if (response.status === 200) {
        // Handle successful login, e.g., redirect to a dashboard
        console.log('Login successful');

        // Redirect to the dashboard
        navigate('/businessdash');

      }
    } catch (error) {
      setError('Login failed. Please check your credentials and try again.');
    }
  };

  return (
    <div className="register-container">
      <h2>Business User Login Form</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form className="form-horizontal" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="control-label col-sm-2" htmlFor="email">
            
          </label>
          <div className="col-sm-10">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-2" htmlFor="pwd">
            
          </label>
          <div className="col-sm-10">
            <input
              type="password"
              className="form-control"
              id="pwd"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-offset-2 col-sm-10">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />{' '}
                Remember me
              </label>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-offset-2 col-sm-10">
            <button type="submit" className="submit-button">
              Log In
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BusinessUserLogin_old;