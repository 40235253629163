import React from "react";

import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'; 
import { ArrowRight1 } from "./icons/ArrowRight1";
import "./styles/business-landing.css";
import NavigationBar from './components/navbar'; 

export const BusinessLanding = () => {

  const navigate = useNavigate(); // useNavigate hook to programmatically navigate

  const handleLoginClick = () => {
    navigate('/business-user-login'); // Navigate to the login page when clicked
  };

  const handleSignupClick = () => {
    navigate('/register-business-user'); // Navigate to the login page when clicked
  };


  return (
    <div className="business-landing">

      <NavigationBar handleLoginClick={handleLoginClick} handleSignupClick={handleSignupClick} prefix={'Biz '} />

      <div className="section-hero">
        <div className="auto-container">
          <div className="frame-LHS">
            <div className="top-text">
              <p className="connect-with-more">
                <span className="span">Connect With More</span>
                <span className="text-wrapper-4">
                  {" "}
                  <br />
                </span>
                <span className="text-wrapper-5">Local Clients</span>
              </p>
              <p className="p">
                List your business with Build Start and really get seen. Access thousands of job postings and get busy!
              </p>
            </div>
            <div className="button-bar">
              <div className="frame-wrapper">
                <div className="frame-4">
                  <p className="stay-competitive-and">Stay Competitive And Join Today!</p>
                  <div className="frame-5">
                    <div className="frame-6">
                      <div className="frame-7">
                        <div className="text-wrapper-6">Join Today</div>
                        <ArrowRight1 className="arrow-right" />
                      </div>
                      <div className="frame-8">
                        <div className="text-wrapper-7">Learn More</div>
                        <img
                          className="icon"
                          alt="Icon"
                          src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e78f69ed209a393a2d5663/img/icon.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-RHS">
            <img
              className="image"
              alt="Image"
              src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/image-204.png"
            />
          </div>
        </div>
      </div>
      <div className="section-biz-connect">
        <div className="auto-container-2">
          <div className="frame-top-text">
            <div className="top-text-2">
              <div className="text-wrapper-8">Live Job Feed</div>
              <p className="text-wrapper-9">
                List your business with BuildStart and get leads for jobs posted by local clients
              </p>
            </div>
          </div>
          <div className="frame-job-feed">
            <div className="frame-job">
              <div className="overlap-group">
                <div className="text-wrapper-10">2009</div>
                <div className="text-wrapper-11">Lurnea</div>
              </div>
              <p className="need-an-electrician">Need An Electrician To Install External Lights</p>
            </div>
            <div className="architect-canley-wrapper">
              <p className="architect-canley">
                <span className="text-wrapper-12">
                  Architect
                  <br />
                </span>
                <span className="text-wrapper-13">
                  Canley Heights 2166
                  <br />
                  <br />
                </span>
                <span className="text-wrapper-14">
                  I need an architect to draw up plans for a granny flat
                  <br />
                </span>
                <span className="text-wrapper-13">
                  <br />
                  <br />
                  <br />
                  16/08/24&nbsp;&nbsp;16:00
                </span>
              </p>
            </div>
            <div className="architect-canley-wrapper">
              <p className="architect-canley">
                <span className="text-wrapper-12">
                  Architect
                  <br />
                </span>
                <span className="text-wrapper-13">
                  Canley Heights 2166
                  <br />
                  <br />
                </span>
                <span className="text-wrapper-14">
                  I need an architect to draw up plans for a granny flat
                  <br />
                </span>
                <span className="text-wrapper-13">
                  <br />
                  <br />
                  <br />
                  16/08/24&nbsp;&nbsp;16:00
                </span>
              </p>
            </div>
            <div className="architect-canley-wrapper">
              <p className="architect-canley">
                <span className="text-wrapper-12">
                  Architect
                  <br />
                </span>
                <span className="text-wrapper-13">
                  Canley Heights 2166
                  <br />
                  <br />
                </span>
                <span className="text-wrapper-14">
                  I need an architect to draw up plans for a granny flat
                  <br />
                </span>
                <span className="text-wrapper-13">
                  <br />
                  <br />
                  <br />
                  16/08/24&nbsp;&nbsp;16:00
                </span>
              </p>
            </div>
          </div>
          <div className="button-bar-2">
            <div className="frame-9">
              <div className="frame-10">
                <div className="frame-11">
                  <div className="frame-12">
                    <div className="frame-13">
                      <div className="text-wrapper-6">List Your Business Today</div>
                      <ArrowRight1 className="arrow-right" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-social">
        <div className="auto-container-3">
          <div className="frame-LHS-2">
            <div className="top-text-3">
              <p className="connect-instantly">
                <span className="text-wrapper-15">
                  Connect Instantly with Professionals via <br />
                </span>
                <span className="text-wrapper-16">our Social Network</span>
              </p>
              <p className="p">List your business with BuildStart and get leads for jobs posted by local clients</p>
            </div>
            <div className="button-bar">
              <div className="frame-4">
                <p className="check-the-live-job">Check The Live Job Feed Below And Join Today!</p>
                <div className="frame-14">
                  <div className="frame-15">
                    <div className="text-wrapper-7">Connect To Social</div>
                    <img
                      className="icon"
                      alt="Icon"
                      src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e78f69ed209a393a2d5663/img/icon.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="img"
            alt="Image"
            src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/image-204-1.png"
          />
        </div>
      </div>
      <div className="section-testimonials">
        <div className="group">
          <div className="frame-16">
            <div className="frame-17">
              <div className="frame-18">
                <div className="frame-19">
                  <div className="frame-20">
                    <div className="frame-21">
                      <div className="text-wrapper-17">“jone Done”</div>
                      <img
                        className="right"
                        alt="Right"
                        src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/right-1.svg"
                      />
                    </div>
                    <p className="text-wrapper-18">
                      Proin eleifend vestibulum tellus vel consectetur. Nunc elementum elit a pellentesque porttitor.
                      Etiam luctus ultricies metus eget feugiat.
                    </p>
                  </div>
                  <div className="frame-22">
                    <img
                      className="frame-23"
                      alt="Frame"
                      src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e92fe99cca8bf1c4d7b5b3/img/frame-1000006446.svg"
                    />
                    <div className="text-wrapper-19">Karolin Boehm</div>
                    <div className="text-wrapper-20">Dec 12, 2023</div>
                  </div>
                </div>
              </div>
              <div className="frame-18">
                <div className="frame-19">
                  <div className="frame-20">
                    <div className="frame-21">
                      <div className="text-wrapper-17">“only Quality Pros Here”</div>
                      <img
                        className="right"
                        alt="Right"
                        src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e92fe99cca8bf1c4d7b5b3/img/right-1-1.svg"
                      />
                    </div>
                    <p className="text-wrapper-18">
                      Proin eleifend vestibulum tellus vel consectetur. Nunc elementum elit a pellentesque porttitor.
                      Etiam luctus ultricies metus eget feugiat.
                    </p>
                  </div>
                  <div className="frame-22">
                    <img
                      className="frame-23"
                      alt="Frame"
                      src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/frame-1000006446.svg"
                    />
                    <div className="text-wrapper-19">Karolin Boehm</div>
                    <div className="text-wrapper-20">Dec 12, 2023</div>
                  </div>
                </div>
              </div>
              <div className="group-2">
                <div className="frame-24">
                  <div className="frame-20">
                    <div className="frame-21">
                      <div className="text-wrapper-17">“it Was Effortless”</div>
                      <img
                        className="right"
                        alt="Right"
                        src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/right-1-2.svg"
                      />
                    </div>
                    <p className="text-wrapper-18">
                      Proin eleifend vestibulum tellus vel consectetur. Nunc elementum elit a pellentesque porttitor.
                      Etiam luctus ultricies metus eget feugiat.
                    </p>
                  </div>
                  <div className="frame-22">
                    <img
                      className="frame-23"
                      alt="Frame"
                      src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/frame-1000006447.svg"
                    />
                    <div className="text-wrapper-19">Karolin Boehm</div>
                    <div className="text-wrapper-20">Dec 12, 2023</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-17">
              <div className="frame-25">
                <div className="frame-19">
                  <div className="frame-20">
                    <div className="frame-21">
                      <p className="text-wrapper-17">“build Start Is My Go To”</p>
                      <img
                        className="quote-right-saying"
                        alt="Quote right saying"
                        src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/quote-right-saying-speech@2x.png"
                      />
                    </div>
                    <p className="text-wrapper-18">
                      Proin eleifend vestibulum tellus vel consectetur. Nunc elementum elit a pellentesque porttitor.
                      Etiam luctus ultricies metus eget feugiat.
                    </p>
                  </div>
                  <div className="frame-22">
                    <img
                      className="frame-23"
                      alt="Frame"
                      src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e92fe99cca8bf1c4d7b5b3/img/frame-1000006446-2.svg"
                    />
                    <div className="text-wrapper-19">Karolin Boehm</div>
                    <div className="text-wrapper-20">Dec 12, 2023</div>
                  </div>
                </div>
              </div>
              <div className="frame-26">
                <div className="frame-19">
                  <div className="frame-20">
                    <div className="frame-27">
                      <p className="if-you-want-a-job">
                        “if You Want A Job Done,
                        <br />
                        use Build Start”
                      </p>
                      <img
                        className="right"
                        alt="Right"
                        src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/right-1-4.svg"
                      />
                    </div>
                    <p className="text-wrapper-18">
                      Proin eleifend vestibulum tellus vel consectetur. Nunc elementum elit a pellentesque porttitor.
                      Etiam luctus ultricies metus eget feugiat.
                    </p>
                  </div>
                  <div className="frame-22">
                    <img
                      className="frame-23"
                      alt="Frame"
                      src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/frame-1000006447-2.svg"
                    />
                    <div className="text-wrapper-19">Karolin Boehm</div>
                    <div className="text-wrapper-20">Dec 12, 2023</div>
                  </div>
                </div>
              </div>
              <div className="frame-26">
                <div className="frame-19">
                  <div className="frame-20">
                    <div className="frame-27">
                      <p className="i-spoke-to-my-sub">
                        “i Spoke To My Sub
                        <br />
                        contractor Directly...”
                      </p>
                      <img
                        className="right"
                        alt="Right"
                        src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/right-1-4.svg"
                      />
                    </div>
                    <p className="text-wrapper-18">
                      Proin eleifend vestibulum tellus vel consectetur. Nunc elementum elit a pellentesque porttitor.
                      Etiam luctus ultricies metus eget feugiat.
                    </p>
                  </div>
                  <div className="frame-22">
                    <img
                      className="frame-23"
                      alt="Frame"
                      src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/frame-1000006447-2.svg"
                    />
                    <div className="text-wrapper-19">Karolin Boehm</div>
                    <div className="text-wrapper-20">Dec 12, 2023</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-28">
          <p className="hear-what-thousands">
            <span className="text-wrapper-21">Hear what thousands of our </span>
            <span className="text-wrapper-22">users think about us</span>
          </p>
          <p className="text-wrapper-23">
            Read about experiences of our users and see how we can connect you with the TOP tradies in town.
          </p>
        </div>
      </div>
      <div className="section-footer">
        <div className="group-3">
          <p className="text-wrapper-24">
            Lorem ipsum dolor sit amet, consect etur adipiscing elit. Mauris a rutrum ipsum. Morbi id ligula volutpat,
            lobo rtis enim quis, elementum purus.
          </p>
          <div className="text-wrapper-25">Build Start</div>
          <div className="text-wrapper-26">Quick Links</div>
          <div className="text-wrapper-27">Directory</div>
          <div className="text-wrapper-28">Resources</div>
          <div className="rectangle" />
          <div className="rectangle-2" />
          <div className="rectangle-3" />
          <p className="for-home-owners-for">
            For Home Owners
            <br />
            For Professionals
            <br />
            Jobs
            <br />
            Social Media
            <br />
            Prices
            <br />
            Education Resources
            <br />
            Contact
          </p>
          <div className="airconditioning">
            Airconditioning
            <br />
            Builders
            <br />
            Concreters
            <br />
            Design
            <br />
            Estimators
            <br />
            Gardeners
            <br />
            More......
          </div>
          <p className="terminology-flow">
            Terminology
            <br />
            Flow Charts
            <br />
            Design Tips
            <br />
            How tos
            <br />
            Support
            <br />
            Our Process
            <br />
            Resource library
          </p>
          <div className="rectangle-4" />
          <p className="text-wrapper-29">© 2024 Build Start. All right reserved.</p>
          <div className="frame-29">
            <img
              className="image-2"
              alt="Image"
              src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/image-190@2x.png"
            />
            <img
              className="image-2"
              alt="Image"
              src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/image-191@2x.png"
            />
            <img
              className="image-2"
              alt="Image"
              src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/image-192@2x.png"
            />
            <img
              className="image-2"
              alt="Image"
              src="https://cdn.animaapp.com/projects/66c06124d7b55e09ed5bf85c/releases/66e903f0ea26ccff29c2332d/img/image-193@2x.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessLanding;
