import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'; 

import { API_BASE_URL } from '../config';
import '../styles/home-user-login.css';
import '../styles/globals.css';

const HomeUserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();  // Use navigate for navigation


  const handleLoginClick = () => {
    navigate('/home-user-login'); // Navigate to the login page when clicked
  };

  const handleSignupClick = () => {
    navigate('/register-home-user'); // Navigate to the login page when clicked
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors

    try {
      const response = await axios.post(`${API_BASE_URL}/api/HomeUsers/login`, {
        email,
        password,
        rememberMe,
      });

      if (response.data.token) {
        // Save the token in localStorage or a secure place
        localStorage.setItem('token', response.data.token);
        console.log('Login successful');

        // Redirect to the dashboard
        navigate('/homedash');
      } else {
        setError('Login failed. Please check your credentials.');
      }
    } catch (error) {
      setError('Login failed. Please check your credentials.');
      console.error('Error:', error);
    }
  };

  return (
    <div className="home-user-login screen">
      {/* Section Header */}

      <div className="section-header section">
        <div className="auto-container">
          <Link to="/" className="lhs-logo"> 
            <img className="image-197 image" src="img/image-197.png" alt="Build Start logo" />
            <div className="build-start">Build Start</div>
          </Link>
          <div className="navbar">
            <div className="navbar-link">Home Owner</div>
            <div className="navbar-link">For Businesses</div>
            <div className="navbar-link">Directory</div>
            <div className="navbar-link">Pricing</div>
            <div className="navbar-link">Info Guides</div>
          </div>
          <div className="login-buttons">
                  <button className="frame-2 login-button" onClick={handleLoginClick}>
                    <div className="login">Login</div>
                  </button>
                  <button className="frame-3 signup-button" onClick={handleSignupClick}>
                    <div className="sign-up">Sign Up</div>
                  </button>
            
          </div>
        </div>
      </div>


      {/* Hero Section */}
      <div className="section-hero section">
        <div className="auto-container-1">
          {/* Left Side */}
          <div className="frame-lhs frame">
            <div className="container">
              <div className="frame-1000006740 frame">
                <h1 className="connect-with-top-pros-today poppins-semi-bold-catalina-blue-32px">
                  Connect With Top Pros <br />today
                </h1>
              </div>
              <div className="frame-1000006739 frame">
                <img className="image-199 image" src="img/image-199.png" alt="Pros Image" />
              </div>
              <div className="frame-1000006742 frame">
                <div className="create-an-account arial-regular-normal-black-15px">Create An Account</div>
              </div>
            </div>
          </div>

          {/* Right Side (Login Form) */}
          <div className="frame-1000006741 frame">
            <div className="frame-rhs frame">
              <div className="home-user-login-1 poppins-semi-bold-catalina-blue-32px">Home User Login</div>

              {/* Error Message */}
              {error && <div className="alert alert-danger">{error}</div>}

              <form className="form-register" onSubmit={handleSubmit}>
                <div className="input-field">
                  <label className="label" htmlFor="email">Email</label>
                  <input
                    className="input"
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="input-field">
                  <label className="label" htmlFor="password">Password</label>
                  <input
                    className="input"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                  />
                </div>

                <div className="checkbox-field">
                  <div className="checkbox-field-item">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="rememberMe" className="label-1 display3regular">Remember me</label>
                  </div>
                </div>

                <div className="button-group">
                  <button type="submit" className="button">
                    <div className="button-1 display3regular">Login</div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeUserLogin;