import React from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config';

function HomePage1() {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Welcome to Build Start Test</h1>
      <div style={{ margin: '20px' }}>

        <div style={{marginTop: '30px'}}>
          <button onClick={() => handleButtonClick('/register-home-user')} style={{ margin: '10px', padding: '10px 20px' }}>
            Register Home User
          </button>
          <button onClick={() => handleButtonClick('/home-user-login')} style={{ margin: '10px', padding: '10px 20px' }}>
            Home User Login
          </button>

          <button onClick={() => handleButtonClick('/register-business-user')} style={{ margin: '10px', padding: '10px 20px' }}>
            Register Business User
          </button>
          <button onClick={() => handleButtonClick('/business-user-login')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business User Login
          </button>
        </div>

        <div style={{marginTop: '30px'}}>
          <button onClick={() => handleButtonClick('/home-user-onboarding-01')} style={{ margin: '10px', padding: '10px 20px' }}>
            Home User Onboarding Step 1
          </button>
          <button onClick={() => handleButtonClick('/home-user-onboarding-015')} style={{ margin: '10px', padding: '10px 20px' }}>
            Home User Onboarding Step 2
          </button>
          <button onClick={() => handleButtonClick('/home-user-onboarding-02')} style={{ margin: '10px', padding: '10px 20px' }}>
            Home User Onboarding Step 3
          </button>
        </div>

        <div style={{marginTop: '30px'}}>
          <button onClick={() => handleButtonClick('/business-onboarding-01')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business Onboarding Step 1
          </button>

          <button onClick={() => handleButtonClick('/business-onboarding-02')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business Onboarding Step 2
          </button>

          <button onClick={() => handleButtonClick('/business-onboarding-03')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business Onboarding Step 3
          </button>

          <button onClick={() => handleButtonClick('/business-onboarding-04')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business Onboarding Step 4
          </button>

          <button onClick={() => handleButtonClick('/business-onboarding-05')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business Onboarding Step 5
          </button>

        </div>


        <div style={{marginTop: '30px'}}>
          <button onClick={() => handleButtonClick('/checkuser')} style={{ margin: '10px', padding: '10px 20px' }}>
            Check Users
          </button>
          <button onClick={() => handleButtonClick('/fullsearch')} style={{ margin: '10px', padding: '10px 20px' }}>
            Full Entity Search
          </button>
        </div>
        {/*
        <button onClick={() => handleButtonClick('/business-user-onboarding')} style={{ margin: '10px', padding: '10px 20px' }}>
          Business User Onboarding
        </button>
        */}



      </div>
    </div>
  );
}

export default HomePage1;