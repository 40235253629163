
import React, { useState } from 'react';
import { API_BASE_URL } from './config';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import NavigationBar from './components/navbar'; 

import { Link } from 'react-router-dom'; 
import './styles/home-user-landing-page.css';
import './styles/globals.css';


const HomeUserLanding = () => {

  const navigate = useNavigate(); // useNavigate hook to programmatically navigate
  const MAX_RESULTS = 20;
  const handleLoginClick = () => {
    navigate('/home-user-login'); // Navigate to the login page when clicked
  };

  const handleSignupClick = () => {
    navigate('/register-home-user'); // Navigate to the login page when clicked
  };


  const [searchTerm, setSearchTerm] = useState('');
  const [postCode, setPostCode] = useState('');



  const [results, setResults] = useState([]);

  // Handle typing in the search box and dynamically fetch professions
  const handleSearchChange = async (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    if (searchValue.length > 0) {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/Profession/searchprofessions?search=${searchValue}`);
        setResults(response.data.slice(0, MAX_RESULTS)); // Limit to MAX_RESULTS
      } catch (error) {
        console.error('Error fetching professions:', error);
      }
    } else {
      setResults([]);
    }
  };

    // Navigate to the search results page when the user submits the form
    const handleSearchSubmit = () => {
      navigate(`/map-search?searchTerm=${searchTerm}&postCode=${postCode}`);
    };

  const handlePostCodeChange = (e) => {
    setPostCode(e.target.value);
  };

  // Clear the search input and results
  const handleClear = () => {
    setSearchTerm('');
    setResults([]); // Clear the search results
  };

  // Set the selected profession in the search box and clear results
  const addItem = (item) => {
    setSearchTerm(`${item.name}`); // Set the search term to selected item
    setResults([]); // Clear the dropdown
  };


  const handleFinalSearch = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/Profession/searchprofessions?search=${searchTerm}&postcode=${postCode}`);
      setResults(response.data); // Handle the results with both search term and postcode
    } catch (error) {
      console.error('Error fetching professions with postcode:', error);
    }
  };

  return (
    <div className="home-user-landing-page">


    <NavigationBar handleLoginClick={handleLoginClick} handleSignupClick={handleSignupClick} prefix={''} />

      <div className="section-hero section">
        <div className="auto-container-1 auto-container-4">
          <div className="frame-lhs">
            <div className="top-text">
              <h1 className="get-any-building-job-done">
                <span className="span0">Get any building<br /></span>
                <span className="span1">Job done</span>
              </h1>
              <p className="connect-with-top-pro poppins-normal-nevada-20px">
                <span>Connect with </span>
                <span className="span1-1">top professionals</span>
                <span> for your building job</span>
              </p>
            </div>
            <div className="x-bar">
              <div className="frame-1000006493">
                <div className="frame-1000006490-1 frame-1000006490-3">
                  <p className="what-service-are-you-looking-for">What Service Are You Looking For?</p>
                  <div className="frame-6-1 frame-6-6">
                    
                        <div className="search-box">
                          <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Profession search"
                            className="search-input"
                          />
                          <button onClick={handleClear} className="clear-button">×</button>
                        </div>

                        {results.length > 0 && (
                          <ul className="dropdown-results">
                            {results.map((item) => (
                              <li key={`${item.type}-${item.id}`} className="result-item">
                                <div className="result-item-content">
                                  <span>{item.name} </span>
                                </div>
                                <button onClick={() => addItem(item)} className="add-button">
                                  +
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    

                </div>
                <div className="frame-1000006492">
                  <div className="postcode">Postcode</div>
                  <div className="frame-6-2 frame-6-6">

                    <div className="postcode-search-box">
                          <input
                            type="text"
                            value={postCode}
                            onChange={handlePostCodeChange}
                            placeholder="Post Code"
                            className="postcode-input"
                          />
                      </div>
                    </div>

                </div>
                
                  <button onClick={handleSearchSubmit} className="search-button" style={{ padding: '10px', marginTop: '10px', border: 'none', background: 'none' }}>
                    <img className="frame-3-1" src="img/frame-3.svg" alt="Frame" />
                  </button>


              </div>
            </div>

            <div className="x-bar">
              <div className="frame-1000006493-1 frame-1000006493-4">
                <div className="frame-1000006737">
                  <div className="frame-1000006738">
                    <div className="frame-1000006490">
                      <div className="frame-6-3 frame-6-6">
                        <div className="post-a-job poppins-medium-midnight-15px">Post A Job</div>
                        <img className="arrow-right" src="img/arrow-right.svg" alt="Arrow right" />
                      </div>
                      <div className="frame-6-4 frame-6-6">
                        <div className="connect-via-our-social-media-platform">Connect Via Our Social Media Platform</div>
                        <img className="send" src="img/send.svg" alt="Send icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-rhs">
            <img className="image-198 image" src="img/image-198.png" alt="main hero" />
          </div>
        </div>
      </div>



      {/* Section Biz Connect */}
      <div className="section-biz-connect">
        <div className="auto-container-4">
          <div className="frame-rhs-1">
            <img className="image-203 image" src="img/image-203.png" alt="Business Connect" />
          </div>
          <div className="frame-lhs-1">
            <div className="top-text-1 top-text-3">
              <p className="connect-with-more-local-clients">Connect With More <br />local Clients</p>
              <p className="list-your-business-w list-your-business poppins-normal-nevada-20px">
                List your business with BuildStart and get leads for jobs posted by local clients
              </p>
            </div>
            <div className="x-bar">
              <div className="frame-1000006493-2 frame-1000006493-4">
                <div className="frame-1000006737">
                  <p className="check-the-live-job-f poppins-medium-midnight-15px">
                    Check The Live Job Feed Below And Join Today!
                  </p>
                  <div className="frame-1000006738">
                    <div className="frame-1000006490">
                      <div className="frame-6">
                        <div className="list-your-business-today list-your-business poppins-medium-midnight-15px">
                          List Your Business Today
                        </div>
                        <img className="arrow-right-3" src="img/arrow-right.svg" alt="Arrow right" />
                      </div>
                      <div className="frame-6-5 frame-6-6">
                        <div className="learn-more">Learn More</div>
                        <img className="icon" src="img/icon.svg" alt="Learn more icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section Biz Connect 1 */}
      <div className="section-biz-connect-1">
        <div className="auto-container-3 auto-container-4">
          <div className="frame-top-text">
            <div className="top-text-2 top-text-3">
              <div className="live-job-feed">Live Job Feed</div>
              <p className="list-your-business-w-1 poppins-normal-nevada-20px">
                List your business with BuildStart and get leads for jobs posted by local clients
              </p>
            </div>
          </div>
          <div className="frame-job-feed">
            <div className="frame-1">
              <p className="architect-canley-hei inter-bold-black-16px">
                <span>Architect<br /></span>
                <span className="span-1 display3regular">Canley Heights 2166<br /><br /></span>
                <span className="span2 display5regular">I need an architect to draw up plans for a granny flat<br /></span>
                <span className="span-1 display3regular"><br />16/08/24&nbsp;&nbsp;16:00</span>
              </p>
            </div>
            {/* Repeat job posts as needed */}
          </div>
          <div className="button-bar">
            <div className="frame-1000006493-3 frame-1000006493-4">
              <div className="frame-1000006737-1">
                <div className="frame-1000006738-1">
                  <div className="frame-1000006490-2 frame-1000006490-3">
                    <div className="frame-6">
                      <div className="list-your-business-today-1 poppins-medium-midnight-15px">
                        List Your Business Today
                      </div>
                      <img className="arrow-right-3" src="img/arrow-right.svg" alt="Arrow right" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>








    </div>
  );
}

export default HomeUserLanding;