import React from 'react';
import { API_BASE_URL } from '../config';

function Dashboard() {
  return (
    <div className="container">
      <h2>Dashboard</h2>
      <p>Welcome to the Dashboard!</p>
    </div>
  );
}

export default Dashboard;