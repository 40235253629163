import React from "react";

export const ArrowRight1 = ({ className }) => {
  return (
    <svg
      className={`arrow-right-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.5 12H19.5M19.5 12L12.5 5M19.5 12L12.5 19"
        stroke="#1E1E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  );
};
