import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { API_BASE_URL } from '../config';
import '../styles/general.css'; 

const OnboardingStep1 = ({ history }) => {
  const [dropdownData, setDropdownData] = useState({
    genders: [],
    ageGroups: [],
    workingStatuses: [],
    livingStatuses: [],
    industries: []
  });
  const [formData, setFormData] = useState({
    gender: '',
    ageGroup: '',
    workingStatus: '',
    livingStatus: '',
    workingIndustry: ''
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/homeonboarding/dropdown-data`);
        setDropdownData(response.data);
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.gender) newErrors.gender = 'Gender is required';
    if (!formData.ageGroup) newErrors.ageGroup = 'Age group is required';
    if (!formData.workingStatus) newErrors.workingStatus = 'Working status is required';
    if (!formData.livingStatus) newErrors.livingStatus = 'Living status is required';
    if (!formData.workingIndustry) newErrors.workingIndustry = 'Industry is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {

      const response = await fetch(`${API_BASE_URL}/api/HomeOnboarding/update-profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: 1, // hard-coded for now
          ageGroup: formData.ageGroup,
          workingStatus: formData.workingStatus,
          livingStatus: formData.livingStatus,
          workingIndustry: formData.workingIndustry,
        }),
      });

      if (response.ok) {
        // Redirect to step 2
        history.push('/onboarding-step-2');
      } else {
        console.error('Error updating profile', response.statusText);
      }

      /*
      axios.defaults.headers.common['Content-Type'] = 'application/json';
      await axios.put(`${API_BASE_URL}/api/HomeOnboarding/update-profile`, {
        userId: 1, // hard-coded for now
        ageGroup: formData.ageGroup,
        workingStatus: formData.workingStatus,
        livingStatus: formData.livingStatus,
        workingIndustry: formData.workingIndustry
      });

      history.push('/onboarding-step-2');

      */


    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div className="register-container">
      <h2>Home User Onboarding Page 1</h2>
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <h3>Tell us a bit about yourself</h3>

      <div className={`form-group ${errors.email ? 'has-error' : ''}`}>

      <div className="col-sm-8">
        <label>Gender</label>
        <select name="gender" value={formData.gender} onChange={handleChange} className="col-sm-8">
          <option value="">Select Gender</option>
          {dropdownData.genders.map((g) => (
            <option key={g.id} value={g.gender}>{g.gender}</option>
          ))}
        </select>
        {errors.gender && <span className="error">{errors.gender}</span>}
      </div>

      <div className="col-sm-8">
        <label>Age Group</label>
        <select name="ageGroup" value={formData.ageGroup} onChange={handleChange} className="col-sm-8">
          <option value="">Select Age Group</option>
          {dropdownData.ageGroups.map((a) => (
            <option key={a.id} value={a.age}>{a.age}</option>
          ))}
        </select>
        {errors.ageGroup && <span className="error">{errors.ageGroup}</span>}
      </div>

      <div className="col-sm-8">
        <label>I am working</label>
        <select name="workingStatus" value={formData.workingStatus} onChange={handleChange} className="col-sm-8">
          <option value="">Select Working Status</option>
          {dropdownData.workingStatuses.map((w) => (
            <option key={w.id} value={w.workingStatus}>{w.workingStatus}</option>
          ))}
        </select>
        {errors.workingStatus && <span className="error">{errors.workingStatus}</span>}
      </div>

      <div className="col-sm-8">
        <label>Living</label>
        <select name="livingStatus" value={formData.livingStatus} onChange={handleChange} className="col-sm-8">
          <option value="">Select Living Status</option>
          {dropdownData.livingStatuses.map((l) => (
            <option key={l.id} value={l.livingStatus}>{l.livingStatus}</option>
          ))}
        </select>
        {errors.livingStatus && <span className="error">{errors.livingStatus}</span>}
      </div>

      <div className="col-sm-8">
        <label>Working Industry</label>
        <select name="workingIndustry" value={formData.workingIndustry} onChange={handleChange} className="col-sm-8">
          <option value="">Select Industry</option>
          {dropdownData.industries.map((i) => (
            <option key={i.industryId} value={i.name}>{i.name}</option>
          ))}
        </select> 
        {errors.workingIndustry && <span className="error">{errors.workingIndustry}</span>}
      </div>

      <button type="submit">Next</button>

      </div>
    </form>
    </div>
  );
};

export default OnboardingStep1;