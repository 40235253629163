import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';

import { FaPlus, FaTimes, FaUser, FaWrench, FaWarehouse, FaBook } from 'react-icons/fa'; // Import icons

import '../styles/general.css'; 
import '../styles/professionsearch.css'; 

const BusinessOnboarding04 = () => {
  const [licenseNumber, setLicenseNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [fullyInsured, setFullyInsured] = useState(false);
  const [certifications, setCertifications] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [error, setError] = useState(null);
  const [showUploadButton, setShowUploadButton] = useState(false); // Add state to control button visibility

  useEffect(() => {
    // Fetch certifications based on professionId (you can pass professionId dynamically)
    const fetchCertifications = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/BusinessOnboarding/certifications?businessId=1`);
        setCertifications(response.data);
      } catch (error) {
        setError("Error fetching certifications");
      }
    };

    fetchCertifications();
  }, []);

  const handleCertificationChange = (id) => {
    setSelectedCertifications((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((cId) => cId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSubmit = async () => {
    try {
      const businessId = 1; // Assume businessId is 1 for now
      await axios.post(`${API_BASE_URL}/api/BusinessOnboarding/saveaccreditation`, {
        businessId,
        licenseNumber,
        licenseExpiry: expiryDate || null, 
        fullyInsured,
        certificationIds: selectedCertifications,
      });
      alert("Accreditation saved successfully!");
    } catch (error) {
      setError("Error saving accreditation");
    }
  };

  return (
    <div className="accreditation-container" style={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
      <h2>What accreditation do you have?</h2>
      <p>Select or add your certifications</p>

      {error && <div className="error-message">{error}</div>}

      {/* License number and expiry date */}
      <div className="license-inputs" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          <label>License Number</label>
          <input
            type="text"
            value={licenseNumber}
            onChange={(e) => setLicenseNumber(e.target.value)}
            style={{ padding: '10px', width: '200px' }}
          />
        </div>
        <div>
          <label>Expiry Date (Optional)</label>
          <input
            type="date"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            style={{ padding: '10px', width: '200px' }}
            placeholder="Optional"
          />
        </div>
      </div>

      {/* Certifications */}
      <div>
        <label>Certifications</label>
        <div className="certifications-grid" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '20px' }}>
          {certifications.map((certification) => {
            const checkboxId = `certification-${certification.certificationId}`;
            return (
              <div key={certification.certificationId} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input
                  type="checkbox"
                  id={checkboxId}
                  checked={selectedCertifications.includes(certification.certificationId)}
                  onChange={() => handleCertificationChange(certification.certificationId)}
                />
                <label htmlFor={checkboxId} style={{ marginLeft: '10px' }}>{certification.name}</label>
              </div>
            );
          })}
        </div>
      </div>

      {/* Fully Insured Toggle */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <label style={{ marginRight: '10px' }}>Fully Insured</label>
        <input
          type="checkbox"
          checked={fullyInsured}
          onChange={() => setFullyInsured(!fullyInsured)}
          style={{ transform: 'scale(1.5)' }}
        />
      </div>

      {/* Upload documentation and next button */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {showUploadButton && (
          <button style={{ padding: '10px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', borderRadius: '5px' }}>
            Upload documentation
          </button>
        )}
        <button onClick={handleSubmit} style={{ padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px' }}>
          Next
        </button>
      </div>
    </div>
  );
};

export default BusinessOnboarding04;