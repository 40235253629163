import React, { useState } from 'react';
import axios from 'axios';
import { FaPlus, FaTimes, FaUser, FaWrench, FaBook } from 'react-icons/fa'; // Import icons
import { API_BASE_URL } from '../config';
import '../styles/professionsearch.css'; 

const ProfessionSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  // Handle typing in the search box and dynamically fetch professions
  const handleSearchChange = async (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
  
    if (searchValue.length > 0) {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/Profession/searchprofessions?search=${searchValue}`);
        //const response = await axios.get(`${API_BASE_URL}/api/Search/searchall?search=${searchValue}`);
        
        console.log(response.data);  // Log the API response to inspect it
        setResults(response.data.slice(0, 20)); // Limit to 10 results
      } catch (error) {
        console.error('Error fetching professions:', error);
      }
    } else {
      setResults([]);
    }
  };


    // Handle clear button click (×)
    const handleClear = () => {
        setSearchTerm('');  // Clear input field
        setResults([]);     // Clear the results
      };

    // Add item (profession or service) to the selected list
    const addItem = (item) => {
        if (!selectedItems.some((p) => p.id === item.id)) {
          setSelectedItems((prevSelected) => {
            const updatedList = [...prevSelected, item];
            console.log('Updated selected items:', updatedList);  // Debugging log
            return updatedList;
          });
        }
      };
    

      // Remove item from the selected list
      const removeItem = (itemId) => {
        setSelectedItems(selectedItems.filter((p) => p.id !== itemId));
      };

  // Submit selected professions to the backend
  const handleSubmit = async () => {
    /*
    const professionIds = selectedProfessions.map((p) => p.professionId);
    const data = {
      user_id: 1, // Replace with actual user_id
      professionIds: professionIds,
    };

    try {
      await axios.post(`${API_BASE_URL}/api/professions_user_is_looking_for`, data);
      alert('Professions saved successfully!');
    } catch (error) {
      console.error('Error saving professions:', error);
    }
      */
  };

  return (
    <div className="profession-search-container">
      {/* Title Section */}
      <h2 className="onboarding-title">Business Onboarding 01</h2>


      <div className="title-spacing"></div>

      <h2 className="onboarding-body">Describe your business</h2>

      <div className="title-spacing"></div>

      <div className="search-box">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search professions"
          className="search-input"
        />
        <button onClick={handleClear} className="clear-button">×</button>
      </div>


        {results.length > 0 && (
                <ul className="dropdown-results">
                {results.map((item) => (
                <li key={item.id} className="result-item">
                    {/* Group icon and name together in a flex container */}
                    <div className="result-item-content">
                    {item.type === 'Profession' ? <FaUser /> : <FaBook />}
                    <span>{item.name} ({item.type})</span>
                    </div>
                    <button onClick={() => addItem(item)} className="add-button">
                    <FaPlus />
                    </button>
                </li>
                ))}
            </ul>

            )}

      <div className="selected-items">
        <h3 className='onboarding-subtitle'>Your selections </h3>
        <div className="item-tags">
          {selectedItems.map((item) => (
            <div key={item.id} className={`item-tag ${item.type === 'Profession' ? 'profession-tag' : 'ancillary-tag'}`}>
              <span>{item.name} ({item.type})</span> {/* Show the type: Profession or Service */}
              <button onClick={() => removeItem(item.id)} className="remove-button">
                <FaTimes />
              </button>
            </div>
          ))}
        </div>
      </div>


      <button onClick={handleSubmit} className="submit-button">Submit</button>
    </div>
  );
};

export default ProfessionSearch;