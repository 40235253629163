import React from 'react';
import { Link } from 'react-router-dom';
import './navbar.css'; // Import the CSS file in the same folder

const NavigationBar = ({ handleLoginClick, handleSignupClick, prefix }) => {
  return (
    <div className="section-header">
      <div className="header-container">
        {/* Logo and Title */}
        <Link to="/" className="lhs-logo">
          <img className="image-197" src="img/image-197.png" alt="Build Start logo" />
          <div className="build-start">Build Start</div>
        </Link>

        {/* Navigation Links */}
        <div className="navbar">
          <Link to="/">
            <div className="text-wrapper-2">Home Owner</div>
          </Link>
          <Link to="/business-home">
            <div className="text-wrapper-2">For Businesses</div>
          </Link>
          <Link to="/directory">
            <div className="text-wrapper-2">Directory</div>
          </Link>
          <Link to="/pricing">
            <div className="text-wrapper-2">Pricing</div>
          </Link>
          <Link to="/info-guides">
            <div className="text-wrapper-2">Info Guides</div>
          </Link>
        </div>

        {/* Login and Sign-Up Buttons */}
        <div className="login-buttons">
          <button className="frame-2 login-button" onClick={handleLoginClick}>
            <div className="login">{prefix} Login</div>
          </button>
          <button className="frame-3 signup-button" onClick={handleSignupClick}>
            <div className="sign-up">{prefix} Sign Up</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;