import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';
// import '../styles/general.css'; 
import '../styles/interests.css'; // Assuming you'll style this

const OnboardingStep15 = () => {
  const [constructionInterests, setConstructionInterests] = useState([]);
  const [personalInterests, setPersonalInterests] = useState([]);
  const [selectedConstructionInterests, setSelectedConstructionInterests] = useState([]);
  const [selectedPersonalInterests, setSelectedPersonalInterests] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch construction interests
    const fetchConstructionInterests = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/HomeOnboarding/constructioninterests`);

        //console.log('Construction Interests Response:', response);

        //console.log('Construction Interests Data:', response.data);

        setConstructionInterests(response.data);
      } catch (error) {
        setError("Error fetching construction interests");
      }
    };

    // Fetch personal interests
    const fetchPersonalInterests = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/HomeOnboarding/personalinterests`);
        setPersonalInterests(response.data);
      } catch (error) {
        setError("Error fetching personal interests");
      }
    };

    fetchConstructionInterests();
    fetchPersonalInterests();
  }, []);

  // Handle checkbox changes for construction interests
  const handleConstructionChange = (id) => {
    setSelectedConstructionInterests((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((cId) => cId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle checkbox changes for personal interests
  const handlePersonalChange = (id) => {
    setSelectedPersonalInterests((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((pId) => pId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSubmit = async () => {
    try {
      const userId = 1; // Assume userId is 1 for now

      // Save construction interests
      await axios.post(`${API_BASE_URL}/api/HomeOnboarding/saveConstructionInterests`, {
        userId: userId,
        cinterestIds: selectedConstructionInterests,
      });

      // Save personal interests
      await axios.post(`${API_BASE_URL}/api/HomeOnboarding/savePersonalInterests`, {
        userId: userId,
        pinterestIds: selectedPersonalInterests,
      });

      alert("Interests saved successfully!");
    } catch (error) {
      setError("Error saving interests");
    }
  };

  return (
    <div className="interests-onboarding">
      <h2>I am interested in:</h2>

      {error && <div className="error-message">{error}</div>}

      <div className="interests-container">
        {/* Construction Interests */}
        <div className="interests-section">
          <h4>Construction Interests</h4>
          <div className="interests-grid">
                {constructionInterests.map((interest) => {
                    const checkboxId = `construction-${interest.cInterestId}`; // Create a unique ID for each checkbox
                    return (
                    <div key={interest.cInterestId}>
                        <input
                        type="checkbox"
                        id={checkboxId} // Set the ID of the checkbox
                        checked={selectedConstructionInterests.includes(interest.cInterestId)}
                        onChange={() => handleConstructionChange(interest.cInterestId)}
                        />
                        <label htmlFor={checkboxId}>{interest.interestName}</label> {/* Link label to input using htmlFor */}
                    </div>
                    );
                })}
                </div>
            </div>

            {/* Personal Interests */}
            <div className="interests-section">
            <h4>Personal Interests</h4>
            <div className="interests-grid">
                {personalInterests.map((interest) => {
                const checkboxId = `personal-${interest.pInterestId}`; // Create a unique ID for each checkbox
                return (
                    <div key={interest.pInterestId}>
                    <input
                        type="checkbox"
                        id={checkboxId} // Set the ID of the checkbox
                        checked={selectedPersonalInterests.includes(interest.pInterestId)}
                        onChange={() => handlePersonalChange(interest.pInterestId)}
                    />
                    <label htmlFor={checkboxId}>{interest.interestName}</label> {/* Link label to input using htmlFor */}
                    </div>
                );
                })}
            </div>
        </div>

        </div>

      <button className="submit-button" onClick={handleSubmit}>Next</button>
    </div>
  );
};

export default OnboardingStep15;