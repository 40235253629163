import React, { useState } from 'react';
import { API_BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'; 

import '../styles/home-user-register.css';  // Assuming the CSS is already available
import '../styles/globals.css';

function RegisterHomeUser() {
  const [formData, setFormData] = useState({
    email: '',
    pwd: '',
    confirmpwd: '',
    firstname: '',
    lastname: '',
    postcode: '',
    phone: ''
  });

  const navigate = useNavigate();  // Use navigate for navigation


  const handleLoginClick = () => {
    navigate('/home-user-login'); // Navigate to the login page when clicked
  };

  const handleSignupClick = () => {
    navigate('/register-home-user'); // Navigate to the login page when clicked
  };


  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');

  const validate = () => {
    const errors = {};

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    if (!formData.pwd) {
      errors.pwd = 'Password is required';
    } else if (!passwordRegex.test(formData.pwd)) {
      errors.pwd = 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number';
    }

    if (!formData.confirmpwd) {
      errors.confirmpwd = 'Confirm Password is required';
    } else if (formData.confirmpwd !== formData.pwd) {
      errors.confirmpwd = 'Passwords do not match';
    }

    if (!formData.firstname) {
      errors.firstname = 'First name is required';
    }

    if (!formData.lastname) {
      errors.lastname = 'Last name is required';
    }

    if (!formData.postcode) {
      errors.postcode = 'Postcode is required';
    }

    if (!formData.phone) {
      errors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = 'Phone number is invalid';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData); 

    if (!validate()) {
      return;
    }

    console.log(formData); 

    try {
      const response = await fetch(`${API_BASE_URL}/api/Register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setMessage('Registration successful!');
      } else {
        console.error('Failed to register user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="home-user-register screen">
      {/* Section Header */}
      <div className="section-header section">
        <div className="auto-container">
          <Link to="/" className="lhs-logo"> 
            <img className="image-197 image" src="img/image-197.png" alt="Build Start logo" />
            <div className="build-start poppins-semi-bold-tulip-tree-24px">Build Start</div>
          </Link>
          <div className="navbar poppins-normal-white-14px">
            <div className="navbar-link">Home Owner</div>
            <div className="navbar-link">For Businesses</div>
            <div className="navbar-link">Directory</div>
            <div className="navbar-link">Pricing</div>
            <div className="navbar-link">Info Guides</div>
          </div>
          <div className="login-buttons">
                  <button className="frame-2 login-button" onClick={handleLoginClick}>
                    <div className="login">Login</div>
                  </button>
                  <button className="frame-3 signup-button" onClick={handleSignupClick}>
                    <div className="sign-up">Sign Up</div>
                  </button>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div className="section-hero section">
        <div className="auto-container-1">
          <div className="frame-lhs frame">
            <div className="container">
              <div className="frame-1000006740 frame">
                <h1 className="join-build-start-today poppins-semi-bold-catalina-blue-32px">Join Build Start<br />today</h1>
              </div>
              <div className="frame-1000006739 frame">
                <img className="image-200 image" src="img/image-200.png" alt="Illustration" />
              </div>
              <div className="frame-1000006742 frame">
                <p className="already-have-an-account-login-here arial-regular-normal-black-15px">
                  Already Have An Account? Login Here
                </p>
              </div>
            </div>
          </div>

          {/* Right Side - Registration Form */}
          <div className="frame-1000006741 frame">
            <div className="frame-rhs frame">
              <div className="frame-1000006743 frame">
                <div className="home-user-registration poppins-semi-bold-catalina-blue-32px">Home User Registration</div>
              </div>

              {message && <div className="alert alert-success">{message}</div>}

              <form className="form-register" onSubmit={handleSubmit}>
                <div className="frame-5-1">
                  <article className={`input-field ${errors.firstname ? 'has-error' : ''}`}>
                    <label className="label">First Name</label>
                    <input
                      type="text"
                      className="input"
                      name="firstname"
                      placeholder="Enter first name"
                      value={formData.firstname}
                      onChange={handleChange}
                    />
                    {errors.firstname && <span className="help-block">{errors.firstname}</span>}
                  </article>
                  <article className={`input-field ${errors.lastname ? 'has-error' : ''}`}>
                    <label className="label">Last Name</label>
                    <input
                      type="text"
                      className="input"
                      name="lastname"
                      placeholder="Enter last name"
                      value={formData.lastname}
                      onChange={handleChange}
                    />
                    {errors.lastname && <span className="help-block">{errors.lastname}</span>}
                  </article>
                </div>

                <div className="frame-5-1">                  
                <article className={`input-field ${errors.postcode ? 'has-error' : ''}`}>
                  <label className="label">Post Code</label>
                  <input
                    type="text"
                    className="input"
                    name="postcode"
                    placeholder="Enter postcode"
                    value={formData.postcode}
                    onChange={handleChange}
                  />
                  {errors.postcode && <span className="help-block">{errors.postcode}</span>}
                </article>


                <article className={`input-field ${errors.phone ? 'has-error' : ''}`}>
                  <label className="label">Phone Number</label>
                  <input
                    type="text"
                    className="input"
                    name="phone"
                    placeholder="Enter Phone number"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {errors.postcode && <span className="help-block">{errors.postcode}</span>}
                </article>
                </div>


                <div className={`input-field-1 ${errors.email ? 'has-error' : ''}`}>
                  <label className="label">Email</label>
                  <input
                    type="email"
                    className="input"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <span className="help-block">{errors.email}</span>}
                </div>
                <div className="frame-5-1">
                <div className={`input-field ${errors.pwd ? 'has-error' : ''}`}>
                  <label className="label">Password</label>
                  <input
                    type="password"
                    className="input"
                    name="pwd"
                    placeholder="Enter password"
                    value={formData.pwd}
                    onChange={handleChange}
                  />
                  {errors.pwd && <span className="help-block">{errors.pwd}</span>}
                </div>
                <div className={`input-field ${errors.confirmpwd ? 'has-error' : ''}`}>
                  <label className="label">Confirm Password</label>
                  <input
                    type="password"
                    className="input"
                    name="confirmpwd"
                    placeholder="Confirm password"
                    value={formData.confirmpwd}
                    onChange={handleChange}
                  />
                  {errors.confirmpwd && <span className="help-block">{errors.confirmpwd}</span>}
                </div>
                </div>


                <div className="button-group">
                  
                  <button type="submit" className="button">
                    <div className="button-1 display3regular">Register</div>
                  </button>
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterHomeUser;