import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';


const BusinessOnboarding05 = () => {
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [yearsExperience, setYearsExperience] = useState(1);  // Default value set to 1
  const [qualifications, setQualifications] = useState([]);
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [selectedMemberships, setSelectedMemberships] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch qualifications based on professionId (pass dynamically)
    const fetchQualifications = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/BusinessOnboarding/qualifications?businessId=1`);
        setQualifications(response.data);
      } catch (error) {
        setError("Error fetching qualifications");
      }
    };

    // Fetch memberships based on professionId
    const fetchMemberships = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/BusinessOnboarding/memberships?businessId=1`);
        setMemberships(response.data);
      } catch (error) {
        setError("Error fetching memberships");
      }
    };

    fetchQualifications();
    fetchMemberships();
  }, []);

  // Handle qualification checkbox toggle
  const handleQualificationChange = (id) => {
    setSelectedQualifications((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((qId) => qId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle membership checkbox toggle
  const handleMembershipChange = (id) => {
    setSelectedMemberships((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((mId) => mId !== id)
        : [...prevSelected, id]
    );
    
  };

  const handleSubmit = async () => {
    try {
      const businessId = 1; // Assume businessId is 1 for now
      const data = {
        businessId,
        registrationNumber: registrationNumber || null, // Set to null if empty
        yearsExperience: yearsExperience || 1, // Default to 1 if empty
        qualificationIds: selectedQualifications.length > 0 ? selectedQualifications : null, // Optional: Send null if no qualifications selected
        membershipIds: selectedMemberships.length > 0 ? selectedMemberships : null, // Optional: Send null if no memberships selected
      };

      await axios.post(`${API_BASE_URL}/api/BusinessOnboarding/saveQualificationsAndMemberships`, data);
      alert("Data saved successfully!");
    } catch (error) {
      setError("Error saving data");
    }
  };

  return (
    <div className="onboarding-container" style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <h2>What Qualifications do you have?</h2>
      <p>Select or add your certifications</p>

      {error && <div className="error-message">{error}</div>}

      {/* Registration number and years of experience */}
      <div className="inputs" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          <label>Registration Number</label>
          <input
            type="text"
            value={registrationNumber}
            onChange={(e) => setRegistrationNumber(e.target.value)}
            style={{ padding: '10px', width: '200px' }}
          />
        </div>
        <div>
          <label>Years Experience</label>
          <input
            type="number"
            value={yearsExperience}
            onChange={(e) => setYearsExperience(e.target.value)}
            style={{ padding: '10px', width: '200px' }}
          />
        </div>
      </div>

      {/* Qualifications */}
      <div>
        <label>Qualifications</label>
        <div className="qualifications-grid" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '20px' }}>
          {qualifications.map((qualification) => {
            const checkboxId = `qualification-${qualification.qualificationId}`;
            return (
              <div key={qualification.qualificationId} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input
                  type="checkbox"
                  id={checkboxId}
                  checked={selectedQualifications.includes(qualification.qualificationId)}
                  onChange={() => handleQualificationChange(qualification.qualificationId)}
                />
                <label htmlFor={checkboxId} style={{ marginLeft: '10px' }}>{qualification.name}</label>
              </div>
            );
          })}
        </div>
      </div>

      {/* Memberships */}
      <div>
        <label>Memberships</label>
        <div className="memberships-grid" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '20px' }}>
          {memberships.map((membership) => {
            const checkboxId = `membership-${membership.membershipId}`;
            return (
              <div key={membership.membershipId} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input
                  type="checkbox"
                  id={checkboxId}
                  checked={selectedMemberships.includes(membership.membershipId)} // Each checkbox is checked if it's selected
                  onChange={() => handleMembershipChange(membership.membershipId)} // Toggle selection
                />
                <label htmlFor={checkboxId} style={{ marginLeft: '10px' }}>{membership.name}</label>
              </div>
            );
          })}
        </div>
      </div>

      {/* Upload documentation and next button */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button style={{ padding: '10px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', borderRadius: '5px' }}>
          Upload documentation
        </button>
        <button onClick={handleSubmit} style={{ padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px' }}>
          Next
        </button>
      </div>
    </div>
  );
};

export default BusinessOnboarding05;