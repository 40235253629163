import React from 'react';
import { API_BASE_URL } from '../config';

function BusinessDashboard() {
  return (
    <div className="container">
      <h2>Business Dashboard</h2>
      <p>Welcome to the Business Dashboard!</p>
    </div>
  );
}

export default BusinessDashboard;