import React, { useState } from 'react';
import { API_BASE_URL } from '../config';
import '../styles/general.css'; 


function RegisterHomeUser_nonformat() {
  const [formData, setFormData] = useState({
    email: '',
    pwd: '',
    confirmpwd: '',
    phone: '',
    firstname: '',
    lastname: '',
    postcode: ''
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');

  const validate = () => {
    const errors = {};

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    if (!formData.pwd) {
      errors.pwd = 'Password is required';
    } else if (!passwordRegex.test(formData.pwd)) {
      errors.pwd = 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number';
    }

    if (!formData.confirmpwd) {
      errors.confirmpwd = 'Confirm Password is required';
    } else if (formData.confirmpwd !== formData.pwd) {
      errors.confirmpwd = 'Passwords do not match';
    }

    if (!formData.phone) {
      errors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = 'Phone number is invalid';
    }

    if (!formData.firstname) {
      errors.firstname = 'First name is required';
    }

    if (!formData.lastname) {
      errors.lastname = 'Last name is required';
    }

    if (!formData.postcode) {
      errors.postcode = 'Postcode is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/Register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setMessage('Registration successful!');
      } else {
        console.error('Failed to register user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="register-container">
      <h2>Home User Registration Form</h2>
      {message && <div className="alert alert-success">{message}</div>}
      <form className="form-horizontal" onSubmit={handleSubmit}>
        <div className={`form-group ${errors.email ? 'has-error' : ''}`}>

          <div className="col-sm-10">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className="help-block">{errors.email}</span>}
          </div>
        </div>
        <div className={`form-group ${errors.pwd ? 'has-error' : ''}`}>

          <div className="col-sm-10">
            <input
              type="password"
              className="form-control"
              id="pwd"
              name="pwd"
              placeholder="Enter password"
              value={formData.pwd}
              onChange={handleChange}
            />
            {errors.pwd && <span className="help-block">{errors.pwd}</span>}
          </div>
        </div>
        <div className={`form-group ${errors.confirmpwd ? 'has-error' : ''}`}>

          <div className="col-sm-10">
            <input
              type="password"
              className="form-control"
              id="confirmpwd"
              name="confirmpwd"
              placeholder="Confirm password"
              value={formData.confirmpwd}
              onChange={handleChange}
            />
            {errors.confirmpwd && <span className="help-block">{errors.confirmpwd}</span>}
          </div>
        </div>
        <div className={`form-group ${errors.phone ? 'has-error' : ''}`}>

          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="Enter phone number"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <span className="help-block">{errors.phone}</span>}
          </div>
        </div>
        <div className={`form-group ${errors.firstname ? 'has-error' : ''}`}>

          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="firstname"
              name="firstname"
              placeholder="Enter first name"
              value={formData.firstname}
              onChange={handleChange}
            />
            {errors.firstname && <span className="help-block">{errors.firstname}</span>}
          </div>
        </div>
        <div className={`form-group ${errors.lastname ? 'has-error' : ''}`}>

          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="lastname"
              name="lastname"
              placeholder="Enter last name"
              value={formData.lastname}
              onChange={handleChange}
            />
            {errors.lastname && <span className="help-block">{errors.lastname}</span>}
          </div>
        </div>
        <div className={`form-group ${errors.postcode ? 'has-error' : ''}`}>

          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="postcode"
              name="postcode"
              placeholder="Enter postcode"
              value={formData.postcode}
              onChange={handleChange}
            />
            {errors.postcode && <span className="help-block">{errors.postcode}</span>}
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-offset-2 col-sm-10">
            <button type="submit" className="submit-button">Register</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default RegisterHomeUser_nonformat;