
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axios from 'axios';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';

import { API_BASE_URL } from '../config';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MapComponent = ({ locations, center }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC0yrTTVQEOV6b64bAVP-UnVZNjfJibmh8",
  });

  const [activeMarker, setActiveMarker] = useState(null);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      center={center}
      zoom={14}  // Increased zoom for a closer view
      mapContainerStyle={{ width: '100%', height: '500px' }}
    >
      {locations.map((location, index) => (
        <Marker
          key={index}
          position={{ lat: location.latitude, lng: location.longitude }}
          onClick={() => setActiveMarker(index)}  // Open info window on marker click
        >
          {activeMarker === index && (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
  <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }}>
    <h4 style={{ margin: 0 }}>{location.businessName}</h4>
    <p style={{ margin: '5px 0' }}>
      <strong>Phone:</strong> {location.phone || 'N/A'}
    </p>
    <p style={{ margin: '5px 0' }}>
      <strong>Address:</strong> {location.streetNumber} {location.streetName}, {location.suburb}, {location.myState}, {location.postCode}
    </p>
  </div>
</InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
};



const MapSearch = () => {
  const query = useQuery();
  const searchTerm = query.get('searchTerm');
  const postCode = query.get('postCode');

  const [results, setResults] = useState([]);
  const [locations, setLocations] = useState([]);
  const [center, setCenter] = useState({ lat: -33.8688, lng: 151.2093 }); // Default to Sydney, Australia

  useEffect(() => {
    const handleSearch = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/search/searchprofpostcode`, {
          searchTerm,
          postCode,
        });

        setResults(response.data.businesses);
        setLocations(response.data.locations);

        // Set the center of the map to the center coordinates returned by the API (near the user's postcode)
        if (response.data.center) {
          setCenter({
            lat: response.data.center.latitude,
            lng: response.data.center.longitude,
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    handleSearch();
  }, [searchTerm, postCode]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '30%', padding: '20px' }}>
        <h3>
          Search Results for "{searchTerm}" in "{postCode}"
        </h3>
        <ul>
          {results.map((result, index) => (
            <li key={index}>
              <h4>{result.businessName}</h4>
              <p>
                {result.streetNumber} {result.streetName}, {result.suburb}, {result.myState}, {result.postCode}
              </p>
              <p>{result.phone ? `Phone: ${result.phone}` : null}</p>
            </li>
          ))}
        </ul>
      </div>

      <div style={{ width: '70%' }}>
        <MapComponent locations={locations} center={center} />
      </div>
    </div>
  );
};

export default MapSearch;