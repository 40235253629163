import React, { useState } from 'react';
import axios from 'axios';
import { FaPlus, FaTimes, FaUser, FaWrench, FaWarehouse, FaBook } from 'react-icons/fa'; // Import icons
import { API_BASE_URL } from '../config';
import '../styles/professionsearch.css'; 

const OnboardingStep2 = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const MAX_RESULTS = 30; 

  // Handle typing in the search box and dynamically fetch professions
  const handleSearchChange = async (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
  
    if (searchValue.length > 0) {
      try {
        //const response = await axios.get(`${API_BASE_URL}/api/Profession/searchcombo?search=${searchValue}`);
        const response = await axios.get(`${API_BASE_URL}/api/Search/searchall?search=${searchValue}`);
        
        console.log(response.data);  // Log the API response to inspect it
        setResults(response.data.slice(0, MAX_RESULTS)); // Limit to 10 results
      } catch (error) {
        console.error('Error fetching professions:', error);
      }
    } else {
      setResults([]);
    }
  };

    // Add item (profession or service) to the selected list
    const addItem = (item) => {
        if (!selectedItems.some((p) => p.id === item.id)) {
          setSelectedItems((prevSelected) => {
            const updatedList = [...prevSelected, item];
            console.log('Updated selected items:', updatedList);  // Debugging log
            return updatedList;
          });
        }
      };
    

      // Remove item from the selected list
      const removeItem = (itemId) => {
        setSelectedItems(selectedItems.filter((p) => p.id !== itemId));
      };

    // Handle clear button click (×)
    const handleClear = () => {
        setSearchTerm('');  // Clear input field
        setResults([]);     // Clear the results
      };



      const handleSubmit = async () => {
        const professionIds = selectedItems
          .filter(item => item.type === 'Profession')
          .map(item => item.id);
        const serviceIds = selectedItems
          .filter(item => item.type === 'Service')
          .map(item => item.id);
        const supplierTypeIds = selectedItems
          .filter(item => item.type === 'SupplierType')
          .map(item => item.id);
        const ancillaryIds = selectedItems
          .filter(item => item.type === 'ProfessionAncillary')
          .map(item => item.id);
    
        const data = {
          userId: 1, // Replace with actual user_id (hardcoded here for simplicity)
          professionIds: professionIds,
          serviceIds: serviceIds,
          supplierTypeIds: supplierTypeIds,
          ancillaryIds: ancillaryIds
        };
    
        try {
          const response = await axios.post(`${API_BASE_URL}/api/HomeOnboarding/saveSearchSelections`, data);
          if (response.status === 200) {
            alert('Selections saved successfully!');
          }
        } catch (error) {
          console.error('Error saving selections:', error);
        }
      };

  return (
    <div className="profession-search-container">
      {/* Title Section */}
      <h2 className="onboarding-title">Entity Search Function</h2>


      <div className="title-spacing"></div>

      <h2 className="onboarding-body">What are you looking for</h2>

      <div className="title-spacing"></div>

      <div className="search-box">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search professions or services"
          className="search-input"
        />
        <button onClick={handleClear} className="clear-button">×</button>
      </div>


        {results.length > 0 && (
        <ul className="dropdown-results">
        {results.map((item) => (
          <li key={item.id} className="result-item">
            {/* Display the correct icon based on the type */}
            <div className="result-item-content">
              {item.type === 'Profession' && <FaUser />}
              {item.type === 'Service' && <FaWrench />}
              {item.type === 'ProfessionAncillary' && <FaBook />} {/* Book icon for ProfessionAncillary */}
              {item.type === 'SupplierType' && <FaWarehouse />}  {/* Warehouse icon for SupplierType */}
              <span>{item.name} ({item.type})</span>
            </div>
            <button onClick={() => addItem(item)} className="add-button">
              <FaPlus />
            </button>
          </li>
        ))}
      </ul>

            )}

<div className="selected-items">
        <h3 className='onboarding-subtitle'>Your selections</h3>
        <div className="item-tags">
          {selectedItems.map((item) => (
            <div key={item.id} className="item-tag">
              <span>{item.name} ({item.type})</span>
              <button onClick={() => removeItem(item.id)} className="remove-button">
                <FaTimes />
              </button>
            </div>
          ))}
        </div>
      </div>

      <button onClick={handleSubmit} className="submit-button">Submit TODO</button>
    </div>
  );
};

export default OnboardingStep2;